<template>
  <div class="gallery" v-if="gallery">
    <base-dialog
      :show="!!error"
      title="An error occurred!"
      @close="handleError"
      fixed
    >
      <p>{{ error }}</p>
    </base-dialog>
    <the-header></the-header>
    <div
      class="max-w-sm px-5 text-left fixed z-50 gallery-title"
      v-if="$mq === 'md' || $mq === 'lg'"
    >
      <h1 class="text-4xl my-5">Gallery</h1>
      <p>
        <span class="gallery-text"
          >Trust is the foundation on which Como is built.</span
        >
      </p>
      <p>
        <span class="gallery-text"
          >Internally trust enables us to work with freedom and drive
          innovation.</span
        >
      </p>
    </div>
    <div
      class="gallery-container"
      :style="{ height: galleryHeight }"
      v-if="$mq === 'md' || $mq === 'lg'"
    >
      <div class="g-1">
        <span @click="showImg(0)">
          <img class="img-cover" :src="gallery[0]" />
        </span>
      </div>
      <div class="g-2">
        <span @click="showImg(1)">
          <img class="img-cover" :src="gallery[1]" />
        </span>
      </div>
      <div class="g-3">
        <span @click="showImg(2)">
          <img class="img-cover" :src="gallery[2]" />
        </span>
      </div>
      <div class="g-4">
        <span @click="showImg(3)">
          <img class="img-cover" :src="gallery[3]" />
        </span>
      </div>
      <div class="g-5">
        <span @click="showImg(4)">
          <img class="img-cover" :src="gallery[4]" />
        </span>
      </div>
      <div class="g-6">
        <span @click="showImg(5)">
          <img class="img-cover" :src="gallery[5]" />
        </span>
      </div>
      <div class="g-7">
        <span @click="showImg(6)">
          <img class="img-cover" :src="gallery[6]" />
        </span>
      </div>
    </div>
    <base-content v-if="$mq === 'sm'">
      <base-page-heading
        title="Gallery"
        copy="Trust is the foundation on which Como is built.Internally trust enables us to work with freedom and drive
            innovation."
      ></base-page-heading>
      <div class="mb-6" @click="showImg(0)">
        <img class="img-cover" :src="gallery[0]" />
      </div>
      <div class="mb-6" @click="showImg(1)">
        <img class="img-cover" :src="gallery[1]" />
      </div>
      <div class="mb-6" @click="showImg(2)">
        <img class="img-cover" :src="gallery[2]" />
      </div>
      <div class="mb-6" @click="showImg(3)">
        <img class="img-cover" :src="gallery[3]" />
      </div>
      <div class="mb-6" @click="showImg(4)">
        <img class="img-cover" :src="gallery[4]" />
      </div>
      <div class="mb-6" @click="showImg(5)">
        <img class="img-cover" :src="gallery[5]" />
      </div>
      <div class="mb-6" @click="showImg(6)">
        <img class="img-cover" :src="gallery[6]" />
      </div>
    </base-content>
    <the-footer></the-footer>
    <vue-easy-lightbox
      moveDisabled
      :visible="visible"
      :imgs="gallery"
      :index="index"
      @hide="handleHide"
    >
      <!-- eslint-disable-next-line vue/no-unused-vars -->
      <template v-slot:toolbar="{ toolbarMethods }">
        <div></div>
      </template>
    </vue-easy-lightbox>
  </div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";
export default {
  name: "Gallery",
  components: {
    VueEasyLightbox,
  },
  data() {
    return {
      isLoading: false,
      error: null,
      visible: false,
      index: 0, // default: 0
      galleryHeight: "0px",
    };
  },
  computed: {
    gallery() {
      try {
        const images = this.$store.getters["categories/images"];
        const gallery = [];
        Object.keys(images).forEach(function(prop) {
          if (images[prop].category === "Gallery") {
            gallery.push(images[prop].large);
          }
        });
        return gallery;
      } catch (error) {
        return null;
      }
    },
  },
  beforeCreate: function() {
    document.body.className = "bg-white";
  },
  created() {
    this.loadCategories();
  },
  updated() {
    this.setGalleryHeight();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.setGalleryHeight);
      this.setGalleryHeight();
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.setGalleryHeight);
  },
  methods: {
    async loadCategories(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("categories/loadCategories", {
          forceRefresh: refresh,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isLoading = false;
    },
    handleError() {
      this.error = null;
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    setGalleryHeight() {
      this.galleryHeight = window.innerHeight - 210 + "px";
    },
  },
};
</script>

<style scoped>
.gallery-text {
  background-color: white;
  box-shadow: 10px 0 0px 0px #fff, -10px 0 0px 0px #fff;
}

.gallery-title {
  left: 5%;
  top: 15%;
}

.gallery-container {
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 7rem 0; */
  position: relative;
  /* height: 400px; */
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 1rem;
  z-index: 60;
  margin-top: 5rem;
  min-height: 710px;
}

.gallery-container > div {
  position: absolute;
  cursor: pointer;
}

.g-1 {
  width: 130px;
  height: 112px;
  left: 319px;
  top: 390px;
  /* top: 500px; */
  /* left: 16.5%;
  top: 70%;
  width: 7%;
  height: 16%; */
}

.g-2 {
  width: 224px;
  height: 234px;
  left: 479px;
  top: 390px;
  /* top: 500px; */
}

.g-3 {
  width: 447px;
  height: 289px;
  left: 572px;
  top: 75px;
  /* top: 183px; */
}

.g-4 {
  width: 447px;
  height: 289px;
  left: 734px;
  top: 390px;
  /* top: 500px; */
}

.g-5 {
  width: 433px;
  height: 236px;
  left: 1052px;
  top: 127px;
  /* top: 238px; */
}

.g-6 {
  width: 265px;
  height: 551px;
  left: 1512px;
  top: 73px;
  /* top: 183px; */
}

.g-7 {
  width: 285px;
  height: 234px;
  left: 1200px;
  top: 390px;
  /* top: 500px; */
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
