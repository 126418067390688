<template>
  <div class="card" :class="theme">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      required: true,
      default: "white",
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 5px;
  box-shadow: 0px 0px 40px rgba(45, 41, 38, 0.15);
  padding: 1.5rem;
  margin: 2rem auto;
  max-width: 40rem;
}
.white {
  background-color: #FFF;
}
.grey {
  background-color: #D7D2CB;
}
</style>