<template>
  <div class="container mx-auto px-6">
    <slot></slot>
  </div>
</template>

<style scoped>
/* .content {
  padding: 3rem 1.5rem 0rem;
  max-width: 40rem;
  margin: 0 auto;

  xl:px-60
} */
</style>