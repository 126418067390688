<template>
  <div class="about" v-if="text && images && gallery">
    <base-dialog
      :show="!!error"
      title="An error occurred!"
      @close="handleError"
      fixed
    >
      <p>{{ error }}</p>
    </base-dialog>
    <base-dialog
      :show="!!makeBooking"
      title="Make a booking"
      @close="handleBooking"
      theme="grey"
      fixed
    >
      <form-booking theme="light" @close="showSentDialog"></form-booking>
    </base-dialog>
    <base-dialog
      :show="!!sendBooking"
      title="Booking Sent!"
      @close="handleSendBooking"
      fixed
    >
    </base-dialog>
    <the-header></the-header>
    <base-content>
      <base-page-heading
        :title="text['about-main-title']"
        :copy="text['about-main-copy']"
      ></base-page-heading>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-x-8 mb-14">
        <div class="order-2 md:order-1 mt-8 md:mt-0">
          <h2 class="text-4xl mb-6">{{ text["about-building-title"] }}</h2>
          <div v-html="text['about-building-copy']" class="mb-10"></div>
          <p class="mb-4">Need more info?</p>
          <div>
            <a href="mailto:coffee@como.london" class="link-orange"
              >Ask us your questions</a
            >
          </div>
          <div>
            <button class="link-orange" @click="handleBooking">
              Make a booking
            </button>
          </div>
        </div>
        <div class="order-1 md:order-2">
          <img
            :src="images['about-building-image'].large"
            class="h-full w-full object-cover"
          />
        </div>
      </div>
    </base-content>
    <div
      ref="gallery"
      id="gallery"
      class="gallery"
    >
      <img v-for="image in gallery" :key="image" :src="image.medium" />
    </div>
    <the-footer></the-footer>
  </div>
</template>

<script>
import FormBooking from "../components/forms/FormBooking.vue";

export default {
  name: "About",
  components: {
    FormBooking,
  },
  data() {
    return {
      isLoading: false,
      error: null,
      makeBooking: false,
      sendBooking: false
    };
  },
  computed: {
    text() {
      return this.$store.getters["categories/text"];
    },
    images() {
      return this.$store.getters["categories/images"];
    },
    gallery() {
      const images = this.$store.getters["categories/images"];
      const gallery = [];
      Object.keys(images).forEach(function(prop) {
        if (images[prop].category === "About-Gallery") {
          gallery.push(images[prop]);
        }
      });
      return gallery;
    },
  },
  beforeCreate: function() {
    document.body.className = "bg-white";
  },
  created() {
    this.loadCategories();
  },
  methods: {
    async loadCategories(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("categories/loadCategories", {
          forceRefresh: refresh,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isLoading = false;
    },
    handleError() {
      this.error = null;
    },
    handleBooking() {
      this.makeBooking = !this.makeBooking;
    },
    showSentDialog() {
      this.handleBooking();
      this.sendBooking = true;
    },
    handleSendBooking() {
      this.sendBooking = false;
    }
  },
  watch: {
    makeBooking(state) {
      if (state) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },
    sendBooking(state) {
      if (state) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },
  },
};
</script>

<style scoped>
.gallery {
  max-height: 400px;
  flex-wrap: nowrap;
  flex-direction: row;
  display: flex;
  height: 400px;
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 1rem;
}

@media (min-width: 768px) {
  .gallery {
    margin-left:8.5rem;
  }
}

.gallery img {
  margin-right: 0.5rem;
  height: 100%;
  width: auto;
  display: inline-block;
  max-width: unset;
}
</style>
