export default {
  // setCategories(state, payload) {
  //   state.categories = payload;
  // },
  setImages(state, payload){
    state.images = payload;
  },
  setText(state, payload){
    state.text = payload;
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  }
}