<template>
  <div class="about" v-if="text && images">
    <base-dialog
      :show="!!error"
      title="An error occurred!"
      @close="handleError"
      fixed
    >
      <p>{{ error }}</p>
    </base-dialog>
    <base-dialog
      :show="!!sendBooking"
      title="Booking Sent!"
      @close="handleSendBooking"
      fixed
    >
    </base-dialog>
    <the-header></the-header>
    <base-content>
      <base-page-heading
        :title="text['contact-main-title']"
        :copy="text['contact-main-copy']"
      ></base-page-heading>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:gap-x-24 md:gap-x-9 mb-14">
        <div class="">
          <img :src="images['contact-peter-image'].large" class="img-col" />
          <h3 class="text-2xl mt-10">{{ text["contact-peter-title"] }}</h3>
          <p class="text-orange mb-5">{{ text["contact-peter-position"] }}</p>
          <p>{{ text["contact-peter-copy"] }}</p>
        </div>
        <div class="mt-10">
          <img :src="images['contact-jose-image'].large" class="img-col" />
          <h3 class="text-2xl mt-10">{{ text["contact-jose-title"] }}</h3>
          <p class="text-orange mb-5">{{ text["contact-jose-position"] }}</p>
          <p>{{ text["contact-jose-copy"] }}</p>
        </div>
      </div>
      <div class="flex flex-wrap my-24">
        <div class="w-full relative">
          <div
            v-if="$mq === 'md' || $mq === 'lg'"
            class="absolute h-full w-full flex justify-start"
          >
            <div class="my-auto">
              <base-card>
                <h2 class="text-center">Make a booking</h2>
                <form-booking
                  theme="dark"
                  button="outline"
                  @close="showSentDialog"
                ></form-booking>
              </base-card>
            </div>
          </div>
          <div class="grid grid-cols-12">
            <div class="col-span-12 md:col-span-10 md:col-start-3">
              <img :src="images['contact-img'].large" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="$mq === 'sm'" class="mb-12">
        <base-card>
          <h2 class="text-center">Make a booking</h2>
          <form-booking
            theme="dark"
            button="outline"
            @close="showSentDialog"
          ></form-booking>
        </base-card>
      </div>
      <div class="grid md:grid-cols-3 md:mt-40">
        <div class="text-center">
          <h3 class="text-2xl mb-3">Contact Us</h3>
          <p>
            <a href="https://goo.gl/maps/wUh2NAwP1arFGqhbA" target="_blank"
              >230 York Way<br />Kings Cross<br />London N7 9AG<br />United
              Kingdom</a
            >
          </p>
        </div>
        <div class="text-center my-10 md:my-0">
          <h3 class="text-2xl mb-3">Phone</h3>
          <p>
            <a href="tel:+447789856973">+44 7789 856 973</a>
          </p>
        </div>
        <div class="text-center">
          <h3 class="text-2xl mb-3">Email</h3>
          <p>
            <a href="mailto:coffee@como.london">coffee@como.london</a>
          </p>
        </div>
      </div>
    </base-content>
    <the-footer></the-footer>
  </div>
</template>

<script>
import FormBooking from "../components/forms/FormBooking.vue";

export default {
  name: "Contact",
  components: {
    FormBooking,
  },
  data() {
    return {
      isLoading: false,
      error: null,
      sendBooking: false,
    };
  },
  computed: {
    text() {
      return this.$store.getters["categories/text"];
    },
    images() {
      return this.$store.getters["categories/images"];
    },
  },
  beforeCreate: function() {
    document.body.className = "bg-white";
  },
  created() {
    this.loadCategories();
  },
  methods: {
    async loadCategories(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("categories/loadCategories", {
          forceRefresh: refresh,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isLoading = false;
    },
    handleError() {
      this.error = null;
    },
    showSentDialog() {
      this.sendBooking = true;
    },
    handleSendBooking() {
      this.sendBooking = false;
    },
  },
  watch: {
    sendBooking(state) {
      if (state) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },
  },
};
</script>

<style scoped>
.img-col {
  height: 450px;
  width: 100%;
  object-fit: cover;
}
</style>
