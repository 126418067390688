export default {
  setUnits(state, payload){
    state.units = payload;
  },
  setStatuses(state, payload){
    state.statuses = payload;
  },
  setFloors(state, payload){
    state.floors = payload;
  },
  setBedrooms(state, payload){
    state.bedrooms = payload;
  },
  setBathrooms(state, payload){
    state.bathrooms = payload;
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  },
  setUnit(state, payload) {
    state.currentUnit = payload;
  },
  setFavourites(state, payload) {
    state.favourites = payload;
  }
}