<template>
  <router-view v-slot="slotProps">
    <transition name="route" mode="out-in">
      <component :is="slotProps.Component"></component>
    </transition>
  </router-view>
</template>

<script>
export default {
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    }
  },
  created() {
    this.$store.dispatch('tryLogin');
  },
  watch: {
    didAutoLogout(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace('/login');
      }
    }
  }
}
</script>

<style>
@font-face {
  font-family: 'Circular Book';
  src:  url('./assets/CircularStdBook.woff2') format('woff2'),
        url('./assets/CircularStdBook.woff') format('woff');
}
@font-face {
  font-family: 'Circular Black';
  src:  url('./assets/CircularStdBlack.woff2') format('woff2'),
        url('./assets/CircularStdBlack.woff') format('woff');
}

* {
  box-sizing: border-box;
}

html {
  font-family: 'Circular Book', sans-serif;
}

body {
  margin: 0;
}

#app {
  font-family: 'Circular Book', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2d2926;
}

/* routes */

.route-enter-from,
.route-leave-to {
  opacity: 0;
}

.route-enter-active {
  transition: all 0.3s ease-out;
}
.route-leave-active {
  transition: all 0.3s ease-in;
}
.route-enter-to,
.route-leave-from {
  opacity: 1;
}

/* global */

h1, h2, h3, h4, h5, h6 {
  font-family: 'Circular Black', sans-serif;
}

.text-center {
  text-align: center;
}

.bg-grey {
  background-color:#D7D2CB;
}

.bg-white {
  background-color:#FFF;
}

.link-orange {
  color: #FE5000;
  font-weight: 700;
  text-decoration: underline;
}

.text-orange {
  color: #FE5000;
}

.text-dark {
  color: #2d2926;
}

button:focus {
  outline: none!important;
}

/* scrollbars */

* {
    scrollbar-width: thin;
    scrollbar-color: #2d2926 #d7d2cb;
}
*::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
*::-webkit-scrollbar-track {
    background: #d7d2cb;
}
*::-webkit-scrollbar-thumb {
    background-color: #2d2926;
    border: 0px solid #d7d2cb;
}

/* Tippy */

.tippy-box {
  background-color: #FFF !important;
}
.tippy-arrow {
  color: #FFF !important;
}

.map-line-color {
  border: 1.5px solid #FFF;
  width: 20px;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 3px;
}

/* Vue-Easy-Lightbox */

.vel-modal {
  backdrop-filter: blur(3px);
  background: rgba(45, 41, 38, 0.5);
}

img.vel-img {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
