import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueMq from "vue3-mq";
import VueTippy from "vue-tippy";
import {showAt, hideAt} from 'vue-breakpoints';

import './main.css';

import TheHeader from './components/ui/TheHeader.vue';
import TheFooter from './components/ui/TheFooter.vue';
import TheMenu from './components/ui/TheMenu.vue';
import BaseContent from './components/ui/BaseContent.vue';
import BaseCard from './components/ui/BaseCard.vue';
import BaseButton from './components/ui/BaseButton.vue';
import BaseDialog from './components/ui/BaseDialog.vue';
import BasePageHeading from './components/ui/BasePageHeading.vue';

const app = createApp(App);

app.use(store).use(router).use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    sm: 450,
    md: 1250,
    lg: Infinity,
  }
}).use(VueTippy);

app.component('the-header', TheHeader);
app.component('the-footer', TheFooter);
app.component('the-menu', TheMenu);
app.component('base-content', BaseContent);
app.component('base-card', BaseCard);
app.component('base-button', BaseButton);
app.component('base-dialog', BaseDialog);
app.component('base-page-heading', BasePageHeading);

app.component('show-at', showAt);
app.component('hide-at', hideAt);

app.mount('#app');
