<template>
  <button v-if="!link" :class="[theme, width]">
    <slot></slot>
  </button>
  <router-link v-else :to="to" :class="theme">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      required: true,
      default: 'outline'
    },
    width: {
      type: String,
      required: false,
      default: 'width-50'
    },
    link: {
      type: Boolean,
      required: false,
      default: false
    },
    to: {
      type: String,
      required: false,
      default: '/'
    }
  }
};
</script>

<style scoped>
button,
a {
  text-decoration: none;
  padding: 0.5rem 1.5rem;
  font: inherit;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  transition: all 0.3s;
  -webkit-tap-highlight-color: transparent;
}

.width-50 {
  width: 50%;
}

.width-100 {
  width: 100%;
}

.width-auto {
  width: auto;
}

.flat {
  background-color: #FFF;
  color: inherit;
  border: none;
}

.outline {
  background-color: transparent;
  color: #FE5000;
  border: 2px solid #FE5000;
}

.flat:hover,
.flat:active,
.flat:focus,
.outline:hover,
.outline:active,
.outline:focus {
  background-color: #FE5000;
  color:#FFF;
  outline:none;
}
</style>