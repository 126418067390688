<template>
  <div class="heading">
    <h1>{{ title }}</h1>
    <div v-html="copy"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    copy: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>
.heading {
  margin-top: 6rem;
  margin-bottom: 3rem;
}
h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}
div {
  text-align: center;
}
</style>