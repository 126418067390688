import { createStore } from 'vuex'

import authModule from './modules/auth/index.js';
import categoriesModule from './modules/categories/index.js';
import unitsModule from './modules/units/index.js';

export default createStore({
  state(){
    return {
      apiURL: 'https://elcho.judder.io/api/v1'
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: authModule,
    categories: categoriesModule,
    units: unitsModule
  }
})
