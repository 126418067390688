export default {
  units(state) {
    return state.units;
  },
  statuses(state){
    return state.statuses;
  },
  floors(state){
    return state.floors;
  },
  bedrooms(state){
    return state.bedrooms;
  },
  bathrooms(state){
    return state.bathrooms;
  },
  shouldUpdate(state) {
    const lastFetch = state.lastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 60; // 1 minute difference, in milliseconds
  },
  currentUnit(state) {
    return state.currentUnit;
  },
  favourites(state) {
    return state.favourites;
  },
  hasFavourites(state) {
    return state.favourites.length;
  }
}