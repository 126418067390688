// eslint-disable-next-line no-unused-vars
let timer;

export default {
  async login(context, payload) {
    return context.dispatch("auth", {
      ...payload,
    });
  },
  async auth(context, payload) {
    let url = context.rootState.apiURL + "/auth/token";

    const response = await fetch(url, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: payload.email,
        password: payload.password
      }),
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message ||
          "Failed to authenticate. Check your login data."
      );
      throw error;
    }

    const dif = (responseData.expiry - (new Date().getTime() / 1000));
    const expiresIn = Math.abs(dif) * 1000;

    console.log(expiresIn);

    localStorage.setItem("token", responseData.access_token);
    localStorage.setItem("userId", responseData.id);
    localStorage.setItem('tokenExpiration', responseData.expiry);

    timer = setTimeout(function() {
      context.dispatch('autoLogout');
    }, expiresIn);

    context.commit("setUser", {
      token: responseData.access_token,
      userId: responseData.id,
    });
  },
  tryLogin(context) {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const tokenExpiration = localStorage.getItem('tokenExpiration');

    const dif = (tokenExpiration - (new Date().getTime() / 1000));
    const expiresIn = Math.abs(dif) * 1000;

    if (expiresIn < 0) {
      return;
    }

    timer = setTimeout(function() {
      context.dispatch('autoLogout');
    }, expiresIn);

    if (token && userId) {
      context.commit("setUser", {
        token: token,
        userId: userId,
      });
    }
  },
  logout(context) {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem('tokenExpiration');

    clearTimeout(timer);

    context.commit("setUser", {
      token: null,
      userId: null,
    });
  },
  autoLogout(context) {
    context.dispatch("logout");
    context.commit("setAutoLogout");
  },
}