<template>
  <form @submit.prevent="makeBooking">
    <div class="form-control">
      <label for="inputName">Name</label>
      <input name="name" type="text" id="inputName" :class="theme" required />
    </div>
    <div class="form-control">
      <label for="inputEmail">Email address</label>
      <input
        name="email"
        type="email"
        id="inputEmail"
        :class="theme"
        required
      />
    </div>
    <div class="form-control">
      <label for="inputTel">Phone number</label>
      <input
        type="tel"
        id="inputTel"
        name="phone"
        pattern="[0-9]*"
        :class="theme"
        required
      />
    </div>
    <div class="grid grid-cols-2 gap-x-8">
      <div class="form-control">
        <label for="inputDate">Date preference</label>
        <input type="date" id="inputDate" name="date" :class="theme" required />
      </div>
      <div class="form-control">
        <label for="inputTime">Time preference</label>
        <input type="time" id="inputTime" name="time" :class="theme" required />
      </div>
    </div>
    <div>
      <label for="inputMessage">Message</label>
      <textarea
        id="inputMessage"
        name="message"
        rows="5"
        :class="theme"
        required
      ></textarea>
    </div>
    <div class="form-control text-center pt-5">
      <base-button :theme="button">Submit</base-button>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      required: false,
      default: "light",
    },
    button: {
      type: String,
      required: false,
      default: "flat",
    },
  },
  emits: ["close"],
  methods: {
    makeBooking() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
form {
  margin: 0;
  padding: 0;
}

.form-control {
  margin: 0.5rem 0;
}

label {
  margin-bottom: 0.5rem;
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 28px;
  text-align: justify;
}

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  font: inherit;
  border: 2px solid #fff;
  border-radius: 4px;
  background-color: transparent;
  padding: 0.15rem 0.5rem;
  font-size: 12px;
  line-height: 24px;
}

input:focus,
textarea:focus {
  border-color: #fe5000;
  background-color: #ffa880;
  outline: none;
}

.dark {
  color: inherit;
}

input.dark,
textarea.dark {
  border: 2px solid #d7d2cb;
}

.light {
  color: #fff;
}

input[type="date"],
input[type="time"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  /* -webkit-appearance: textfield;
    -moz-appearance: textfield; */
  min-height: 2rem;
}
</style>
