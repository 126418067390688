<template>
  <div ref="pswp" class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
    <!-- Background of PhotoSwipe. 
    It's a separate element as animating opacity is faster than rgba().-->
    <div class="pswp__bg"></div>

    <!-- Slides wrapper with overflow:hidden. -->
    <div class="pswp__scroll-wrap">
      <!-- Container that holds slides. 
            PhotoSwipe keeps only 3 of them in the DOM to save memory.
      Don't modify these 3 pswp__item elements, data is added later on.-->
      <div class="pswp__container">
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
      </div>

      <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
      <div class="pswp__ui pswp__ui--hidden">
        <div class="pswp__top-bar">
          <!--  Controls are self-explanatory. Order can be changed. -->

          <div class="pswp__counter"></div>

          <button
            class="pswp__button pswp__button--close"
            title="Close (Esc)"
          ></button>

          <!-- <button class="pswp__button pswp__button--share" title="Share"></button> -->

          <button
            class="pswp__button pswp__button--fs"
            title="Toggle fullscreen"
          ></button>

          <button
            class="pswp__button pswp__button--zoom"
            title="Zoom in/out"
          ></button>

          <button
            v-if="parent.isFavourite"
            @click="parent.removeFavourite"
            class="pswp__button favourite"
            title="Remove from favourites"
          >
            <img
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyNSAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIxLjcwODMgMi44MDIwM0MyMS4xNzYzIDIuMjY5NzQgMjAuNTQ0NiAxLjg0NzUgMTkuODQ5MyAxLjU1OTQxQzE5LjE1NCAxLjI3MTMzIDE4LjQwODggMS4xMjMwNSAxNy42NTYyIDEuMTIzMDVDMTYuOTAzNiAxLjEyMzA1IDE2LjE1ODQgMS4yNzEzMyAxNS40NjMyIDEuNTU5NDFDMTQuNzY3OSAxLjg0NzUgMTQuMTM2MiAyLjI2OTc0IDEzLjYwNDEgMi44MDIwM0wxMi41IDMuOTA2MkwxMS4zOTU4IDIuODAyMDNDMTAuMzIxMSAxLjcyNzM1IDguODYzNTYgMS4xMjM2IDcuMzQzNzMgMS4xMjM2QzUuODIzOTEgMS4xMjM2IDQuMzY2MzMgMS43MjczNSAzLjI5MTY1IDIuODAyMDNDMi4yMTY5NyAzLjg3NjcxIDEuNjEzMjIgNS4zMzQyOSAxLjYxMzIyIDYuODU0MTFDMS42MTMyMiA4LjM3Mzk0IDIuMjE2OTcgOS44MzE1MiAzLjI5MTY1IDEwLjkwNjJMNC4zOTU4MSAxMi4wMTA0TDEyLjUgMjAuMTE0NUwyMC42MDQxIDEyLjAxMDRMMjEuNzA4MyAxMC45MDYyQzIyLjI0MDYgMTAuMzc0MiAyMi42NjI4IDkuNzQyNDYgMjIuOTUwOSA5LjA0NzE5QzIzLjIzOSA4LjM1MTkyIDIzLjM4NzMgNy42MDY3IDIzLjM4NzMgNi44NTQxMUMyMy4zODczIDYuMTAxNTIgMjMuMjM5IDUuMzU2MzEgMjIuOTUwOSA0LjY2MTA0QzIyLjY2MjggMy45NjU3NyAyMi4yNDA2IDMuMzM0MDcgMjEuNzA4MyAyLjgwMjAzVjIuODAyMDNaIiBmaWxsPSIjRkU1MDAwIiBzdHJva2U9IiNGRTUwMDAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo="
            />
          </button>
          <button
            v-else
            @click="parent.addFavourite"
            class="pswp__button favourite"
            title="Add to favourites"
          >
            <img
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyNSAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIxLjcwODMgMi44MDIwM0MyMS4xNzYzIDIuMjY5NzQgMjAuNTQ0NiAxLjg0NzUgMTkuODQ5MyAxLjU1OTQxQzE5LjE1NCAxLjI3MTMzIDE4LjQwODggMS4xMjMwNSAxNy42NTYyIDEuMTIzMDVDMTYuOTAzNiAxLjEyMzA1IDE2LjE1ODQgMS4yNzEzMyAxNS40NjMyIDEuNTU5NDFDMTQuNzY3OSAxLjg0NzUgMTQuMTM2MiAyLjI2OTc0IDEzLjYwNDEgMi44MDIwM0wxMi41IDMuOTA2MkwxMS4zOTU4IDIuODAyMDNDMTAuMzIxMSAxLjcyNzM1IDguODYzNTYgMS4xMjM2IDcuMzQzNzMgMS4xMjM2QzUuODIzOTEgMS4xMjM2IDQuMzY2MzMgMS43MjczNSAzLjI5MTY1IDIuODAyMDNDMi4yMTY5NyAzLjg3NjcxIDEuNjEzMjIgNS4zMzQyOSAxLjYxMzIyIDYuODU0MTFDMS42MTMyMiA4LjM3Mzk0IDIuMjE2OTcgOS44MzE1MiAzLjI5MTY1IDEwLjkwNjJMNC4zOTU4MSAxMi4wMTA0TDEyLjUgMjAuMTE0NUwyMC42MDQxIDEyLjAxMDRMMjEuNzA4MyAxMC45MDYyQzIyLjI0MDYgMTAuMzc0MiAyMi42NjI4IDkuNzQyNDYgMjIuOTUwOSA5LjA0NzE5QzIzLjIzOSA4LjM1MTkyIDIzLjM4NzMgNy42MDY3IDIzLjM4NzMgNi44NTQxMUMyMy4zODczIDYuMTAxNTIgMjMuMjM5IDUuMzU2MzEgMjIuOTUwOSA0LjY2MTA0QzIyLjY2MjggMy45NjU3NyAyMi4yNDA2IDMuMzM0MDcgMjEuNzA4MyAyLjgwMjAzVjIuODAyMDNaIiBzdHJva2U9IiNGRTUwMDAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo="
            />
          </button>

          <!-- Preloader demo http://codepen.io/dimsemenov/pen/yyBWoR -->
          <!-- element will get class pswp__preloader--active when preloader is running -->
          <div class="pswp__preloader">
            <div class="pswp__preloader__icn">
              <div class="pswp__preloader__cut">
                <div class="pswp__preloader__donut"></div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap"
        >
          <div class="pswp__share-tooltip"></div>
        </div>

        <button
          class="pswp__button pswp__button--arrow--left"
          title="Previous (arrow left)"
        ></button>

        <button
          class="pswp__button pswp__button--arrow--right"
          title="Next (arrow right)"
        ></button>

        <div class="pswp__caption">
          <div class="pswp__caption__center"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";
import PhotoSwipe from "photoswipe/dist/photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";

import loadImages from "./loadImages.js";

export default {
  data() {
    return {
      pswp: null,
      parent: {},
    };
  },
  methods: {
    async init(images = [], options = {}, parent) {
      this.parent = parent;

      const items = await loadImages(images);
      const {
        index = 0,
        history = false,
        change,
        defaultOptions = {},
      } = options;
      if (this.pswp) {
        return;
      }
      const finalOptions = {
        index,
        history,
        clickToCloseNonZoomable: false,
        ...defaultOptions,
      };

      this.pswp = new PhotoSwipe(
        this.$refs.pswp,
        PhotoSwipeUI_Default,
        items,
        finalOptions
      );
      this.pswp.init();
      this.pswp.listen("close", this.close);
      if (change) {
        this.pswp.listen("afterChange", () => {
          change(this.pswp.getCurrentIndex());
        });
      }
    },
    async push(images = [], options = {}) {
      if (!this.pswp) {
        this.init(arguments);
      }

      const items = await loadImages(images);

      this.pswp.items.push(...items);
      this.pswp.invalidateCurrItems();
      this.pswp.updateSize(true);

      if (options.index !== undefined) {
        this.pswp.goTo(options.index);
      }
    },
    close() {
      if (!this.pswp) return;
      this.pswp.close();
      this.pswp = null;
    }
  },
  beforeUnmount() {
    this.close();
  },
};
</script>

<style scoped>
.favourite {
  background: none;
}
.favourite img {
  height: 16px;
  margin: 0 auto;
}
</style>
