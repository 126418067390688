<template>
  <div
    class="finder h-screen flex flex-col"
    v-if="units && statuses && floors && bedrooms"
  >
    <base-dialog
      :show="!!error"
      title="An error occurred!"
      @close="handleError"
      fixed
    >
      <p>{{ error }}</p>
    </base-dialog>
    <unit-details
      :show="!!showUnit"
      :unit="currentUnit"
      @close="unsetUnit"
    ></unit-details>
    <the-header theme="grey"></the-header>
    <div class="flex-grow">
      <iframe
        id="iframe"
        :src="src"
        ref="iframe"
        class="h-screen w-full"
      ></iframe>
    </div>
    <div class="fixed inset-0 pointer-events-none">
      <div :class="[{ 'details-panel-show': filterPanel }, 'details-panel', 'pointer-events-auto']">
        <div v-if="$mq === 'sm'" class="text-center">
          <button @click="togglePanel">
            <img
              :class="[{ 'img-arrow-btn-open': filterPanel}, 'img-arrow-btn']"
              width="25"
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDIwMDAgMjAwMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgY2xhc3M9IiI+PGc+PGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Zz48cGF0aCBkPSJtMTc0NCAxNDM2Yy0xNi40IDAtMzIuOC02LjItNDUuMy0xOC43bC02OTguNy02OTguOC02OTguNyA2OTguN2MtMjUgMjUtNjUuNSAyNS05MC41IDBzLTI1LTY1LjUgMC05MC41bDc0NC03NDRjMjUtMjUgNjUuNS0yNSA5MC41IDBsNzQ0IDc0NGMyNSAyNSAyNSA2NS41IDAgOTAuNS0xMi41IDEyLjYtMjguOSAxOC44LTQ1LjMgMTguOHoiIGZpbGw9IiNmZmZmZmYiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIHN0eWxlPSIiIGNsYXNzPSIiPjwvcGF0aD48L2c+PC9nPjwvZz48L3N2Zz4="
            />
          </button>
        </div>
        <base-card class="mt-0" theme="grey">
          <h2 class="text-center">Find an apartment</h2>
          <form @submit.prevent="search">
            <div class="form-control">
              <label for="status">Status</label>
              <select name="status" id="status" v-model="filterStatus">
                <option value="0">None</option>
                <option
                  v-for="status in statuses"
                  :key="status.id"
                  :value="status.id"
                >
                  {{ status.name }}
                </option>
              </select>
            </div>
            <div class="form-control">
              <label for="floor">Floor</label>
              <select name="floor" id="floor" v-model="filterFloor">
                <option value="0">None</option>
                <option v-for="floor in floors" :key="floor.id" :value="floor.id">
                  {{ floor.name }}
                </option>
              </select>
            </div>
            <div class="grid grid-cols-2 gap-x-4">
              <div class="form-control">
                <label for="bedroom">Bedroom</label>
                <select name="bedroom" id="bedroom" v-model="filterBedroom">
                  <option value="0">None</option>
                  <option
                    v-for="bedroom in bedrooms"
                    :key="bedroom.id"
                    :value="bedroom.id"
                  >
                    {{ bedroom.name }}
                  </option>
                </select>
              </div>
              <div class="form-control">
                <label for="bathroom">Bathroom</label>
                <select name="bathroom" id="bathroom" v-model="filterBathroom">
                  <option value="0">None</option>
                  <option
                    v-for="bathroom in bathrooms"
                    :key="bathroom.id"
                    :value="bathroom.id"
                  >
                    {{ bathroom.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-control-mb-0 text-center">
              <base-button theme="flat" width="width-100">Search</base-button>
            </div>
          </form>
        </base-card>
      </div>
    </div>
  </div>
</template>

<script>
const { default: UnitDetails } = require("../components/units/UnitDetails.vue");

export default {
  components: { UnitDetails },
  name: "Finder",
  data() {
    return {
      isLoading: false,
      error: null,
      src: "https://playcanv.as/e/p/SaZoF0b8/",
      iframeWin: null,
      filterStatus: 0,
      filterFloor: 0,
      filterBedroom: 0,
      filterBathroom: 0,
      // showUnit: false,
      filterPanel: false,
    };
  },
  computed: {
    units() {
      return this.$store.getters["units/units"];
    },
    statuses() {
      return this.$store.getters["units/statuses"];
    },
    floors() {
      return this.$store.getters["units/floors"];
    },
    bedrooms() {
      return this.$store.getters["units/bedrooms"];
    },
    bathrooms() {
      return this.$store.getters["units/bathrooms"];
    },
    currentUnit() {
      return this.$store.getters["units/currentUnit"];
    },
    showUnit() {
      return (Object.keys(this.$store.getters["units/currentUnit"]).length === 0 ? false : true);
    }
  },
  beforeCreate: function() {
    document.body.className = "bg-grey";
  },
  created() {
    this.loadUnits();
    this.loadStatuses();
    this.loadFloors();
    this.loadBedrooms();
    this.loadBathrooms();
  },
  mounted() {
    window.addEventListener("message", (event) => {
      const data = event.data;
      switch (data.cmd) {
        case "showUnit":
          this.setUnit(data.params.id);
          break;
      }
    });
    // this.iframeWin = this.$refs.iframe.contentWindow;
  },
  methods: {
    async loadUnits(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("units/loadUnits", {
          forceRefresh: refresh,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isLoading = false;
    },
    async loadStatuses(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("units/loadStatuses", {
          forceRefresh: refresh,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isLoading = false;
    },
    async loadFloors(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("units/loadFloors", {
          forceRefresh: refresh,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isLoading = false;
    },
    async loadBedrooms(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("units/loadBedrooms", {
          forceRefresh: refresh,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isLoading = false;
    },
    async loadBathrooms(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("units/loadBathrooms", {
          forceRefresh: refresh,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isLoading = false;
    },
    handleError() {
      this.error = null;
    },
    search() {
      const iframe = document.getElementById("iframe").contentWindow;
      iframe.postMessage(
        {
          cmd: "filter",
          params: {
            status: this.filterStatus,
            floor: this.filterFloor,
            bedroom: this.filterBedroom,
            bathroom: this.filterBathroom,
          },
        },
        "https://playcanv.as/"
      );
      this.filterPanel = false;
    },
    setUnit(id) {
      const units = this.$store.getters["units/units"];
      const unit = units[id];
      this.$store.dispatch("units/setUnit", unit);
      // this.showUnit = true;
    },
    unsetUnit() {
      this.$store.dispatch("units/setUnit", {});
      // this.showUnit = false;
    },
    togglePanel() {
      this.filterPanel = !this.filterPanel;
    }
  }
};
</script>

<style scoped>
.details-panel {
  position: absolute;
  width: 100%;
  top: calc(100% - 95px);
  left: unset;
  bottom: unset;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  z-index: 75;
  transition: all 1s;
  margin: 0 !important;
}

.details-panel-show {
  transform: translateY(-70%);
}

@media (min-width: 768px) {
  .details-panel {
    left: 1.5rem;
    bottom: 1.5rem;
    width: 315px;
    top: unset;
    padding-left: 0;
    padding-right: 0;
  }
}

form {
  margin: 0;
  padding: 0;
}

.form-control {
  margin: 0.5rem 0;
}

.form-control-mb-0 {
  margin: 0.5rem 0 0;
}

label {
  margin-bottom: 0.5rem;
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 28px;
  text-align: justify;
}

input,
textarea,
select {
  display: block;
  width: 100%;
  font: inherit;
  border: 2px solid #fff;
  border-radius: 4px;
  background-color: transparent;
  padding: 0.15rem 0.5rem;
  font-size: 12px;
  line-height: 24px;
  color: #fff;
}

select {
  padding: 0.5rem;
}

select option {
  color: #2d2926;
}

input:focus,
textarea:focus {
  border-color: #fe5000;
  background-color: #ffa880;
  outline: none;
}

.mt-0 {
  margin-top: 0!important;
}

.img-arrow-btn {
    transition: transform 1s ease-in-out;
}

.img-arrow-btn-open {
    transform: rotate(180deg);
}
</style>
