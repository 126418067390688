export default {
  async loadUnits(context, payload) {
    if (!payload.forceRefresh && !context.getters.shouldUpdate) {
      return;
    }

    const url = context.rootState.apiURL + "/units";

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootState.auth.token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch!");
      throw error;
    }

    const units = {};

    responseData.data.forEach(function(unit) {
      units[unit.id] = unit;
    });

    context.commit("setUnits", units);
    context.commit("setFetchTimestamp");
  },
  async loadStatuses(context, payload) {
    if (!payload.forceRefresh && !context.getters.shouldUpdate) {
      return;
    }

    const url = context.rootState.apiURL + "/units/properties/status";

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootState.auth.token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch!");
      throw error;
    }

    const statuses = [];

    responseData.data.forEach(function(status) {
      statuses.push(status);
    });

    context.commit("setStatuses", statuses);
    context.commit("setFetchTimestamp");
  },
  async loadFloors(context, payload) {
    if (!payload.forceRefresh && !context.getters.shouldUpdate) {
      return;
    }

    const url = context.rootState.apiURL + "/units/properties/floor";

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootState.auth.token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch!");
      throw error;
    }

    const floors = [];

    responseData.data.forEach(function(floor) {
      floors.push(floor);
    });

    context.commit("setFloors", floors);
    context.commit("setFetchTimestamp");
  },
  async loadBedrooms(context, payload) {
    if (!payload.forceRefresh && !context.getters.shouldUpdate) {
      return;
    }

    const url = context.rootState.apiURL + "/units/properties/bedroom";

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootState.auth.token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch!");
      throw error;
    }

    const bedrooms = [];

    responseData.data.forEach(function(bedroom) {
      bedrooms.push(bedroom);
    });

    context.commit("setBedrooms", bedrooms);
    context.commit("setFetchTimestamp");
  },
  async loadBathrooms(context, payload) {
    if (!payload.forceRefresh && !context.getters.shouldUpdate) {
      return;
    }

    const url = context.rootState.apiURL + "/units/properties/bathroom";

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootState.auth.token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch!");
      throw error;
    }

    const bathrooms = [];

    responseData.data.forEach(function(bathroom) {
      bathrooms.push(bathroom);
    });

    context.commit("setBathrooms", bathrooms);
    context.commit("setFetchTimestamp");
  },
  setUnit(context, payload) {
    context.commit("setUnit", payload);
  },
  addFavourite(context, payload) {
    const favourites = context.getters.favourites;
    favourites.push(payload);
    
    context.commit("setFavourites", favourites);
  },
  removeFavourite(context, payload) {
    const favourites = context.getters.favourites;
    // const newFavourites = favourites.filter(favourite => favourite !== payload);
    const newFavourites = favourites.filter(favourite => !favourite[payload]);

    context.commit("setFavourites", newFavourites);
  },
  clearFavourites(context) {
    context.commit("setFavourites", []);
  }
};
