import { createRouter, createWebHistory } from "vue-router";
import store from '../store/index.js';
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Location from "../views/Location.vue";
import Finder from "../views/Finder.vue";
import Gallery from "../views/Gallery.vue";
import Contact from "../views/Contact.vue";
import Favourites from "../views/Favourites.vue";
import Login from "../views/Login.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: { requiresAuth: true }
  },
  {
    path: "/location",
    name: "Location",
    component: Location,
    meta: { requiresAuth: true }
  },
  {
    path: "/finder",
    name: "Finder",
    component: Finder,
    meta: { requiresAuth: true }
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: Gallery,
    meta: { requiresAuth: true }
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: { requiresAuth: true }
  },
  {
    path: "/favourites",
    name: "Favourites",
    component: Favourites,
    meta: { requiresAuth: true }
  },
  { path: '/login', component: Login, meta: { requiresUnauth: true } },
  { path: "/:notFound(.*)", redirect: "/" },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return {
      left: 0,
      top: 0
    };
  }
});

router.beforeEach(function(to, from, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next('/login');
  } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

export default router;
