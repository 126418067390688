export default {
  async loadCategories(context, payload) {
    if (!payload.forceRefresh && !context.getters.shouldUpdate) {
      return;
    }

    const url = context.rootState.apiURL + "/categories";

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + context.rootState.auth.token,
      },
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch!");
      throw error;
    }

    const images = {};
    const text = {};

    responseData.data.forEach(function(category) {
      category.contents.forEach(function(element) {
        if (Object.prototype.hasOwnProperty.call(element, "text")) {
          text[element.name] = element.text.replace(
            /(?:\r\n|\r|\n)/g,
            "<br />"
          );
        }

        if (Object.prototype.hasOwnProperty.call(element, "images")) {
          images[element.name] = {
            category: category.name,
            small: element.images.small,
            medium: element.images.medium,
            large: element.images.large
          };
        }
      });
    });

    context.commit("setImages", images);
    context.commit("setText", text);
    context.commit("setFetchTimestamp");
  },
};
