import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      lastFetch: null,
      units: null,
      statuses: null,
      floors: null,
      bedrooms: null,
      bathrooms: null,
      currentUnit: {},
      favourites: []
    };
  },
  mutations,
  actions,
  getters
};