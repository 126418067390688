<template>
  <teleport to="body">
    <transition name="fade">
      <div class="modal" v-if="show">
        <div class="modal__backdrop" @click="tryClose" />
        <div v-if="show" open :class="[theme, 'modal__dialog']">
          <header class="modal__header">
            <slot name="header">
              <h2>{{ title }}</h2>
            </slot>
            <button
              type="button"
              class="close"
              aria-label="Close"
              @click="tryClose"
            >
              <img
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjciIGhlaWdodD0iMjciIHZpZXdCb3g9IjAgMCAyNyAyNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI2IDFMMSAyNiIgc3Ryb2tlPSIjMkQyOTI2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMSAxTDI2IDI2IiBzdHJva2U9IiMyRDI5MjYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo="
              />
            </button>
          </header>
          <section class="modal__body">
            <slot></slot>
          </section>
          <div v-if="!fixed" class="modal__footer">
            <slot name="actions">
              <base-button @click="tryClose">Close</base-button>
            </slot>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    fixed: {
      type: Boolean,
      required: true,
      default: false,
    },
    theme: {
      type: String,
      required: false,
      default: "white",
    },
  },
  emits: ["close"],
  methods: {
    tryClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 95;
}
.modal__backdrop {
  backdrop-filter: blur(3px);
  background: rgba(45, 41, 38, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.modal__dialog {
  background-color: #ffffff;
  position: relative;
  width: 600px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  z-index: 2;
}
@media screen and (max-width: 992px) {
  .modal__dialog {
    width: 90%;
  }
}
.modal__close {
  width: 30px;
  height: 30px;
}
.modal__header {
  padding: 20px 20px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.modal__body {
  padding: 10px 20px 10px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.modal__footer {
  padding: 10px 20px 20px;
  text-align: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  backdrop-filter: blur(0);
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
  backdrop-filter: blur(3px);
}

.white {
  background-color: #FFF;
}
.grey {
  background-color: #D7D2CB;
}
.close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
.close img {
  height: 15px;
  width: 15px;
}
</style>
